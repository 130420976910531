<script>
import Layout from "../../_layouts/main"
import PageHeader from "@/components/page-header"

/**
 * Dashboard Component
 */
export default {
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: this.$t('소개')
    };
  },
  mounted() {

  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h5>머니이동에서 사용되는 API입니다.</h5>
            <hr />
            <ul>
              <li><label><router-link to="/api/transfer/register">회원가입</router-link>:</label> 사이트의 회원을 가입시킵니다. 게임실행에 포함된 기능입니다.</li>
              <li><label><router-link to="/api/transfer/play">게임실행</router-link>:</label> 게임을 실행합니다. 회원가입, 충전, 게임실행이 자동으로 실행됩니다.</li>
              <li><label><router-link to="/api/transfer/user/balance">회원잔액</router-link>:</label> 회원의 남은 잔액을 확인합니다.</li>
              <li><label><router-link to="/api/transfer/user/balance/callback">회원잔액(콜백)</router-link>:</label> 회원의 잔액 변동 정보를 실시간으로 보내드리며, 배팅등의 경우 금액, 게임 정보 등을 같이 보내드립니다.</li>
              <li><label><router-link to="/api/transfer/deposit">충전</router-link>:</label> 회원의 잔액을 충전합니다.</li>
              <li><label><router-link to="/api/transfer/withdraw">환전</router-link>:</label> 회원의 남은 잔액을 모두 환전합니다.</li>
              <li><label><router-link to="/api/transfer/transactions">배팅내역</router-link>:</label> 회원의 배팅내역을 일자검색을 통하여 확인할 수 있습니다.</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
